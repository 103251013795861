<!-- Footer.vue -->
<template>
   <div class="container-fluid py-3 footer-section">
      <video autoplay muted loop id="footer-video">
         <source src="../../../public/images/footer.webm" type="video/mp4">
      </video>
      <div class="container">
         <div class="row">
            <div class="col-lg-4 col-sm-6 ps-lg-5">
               <div class="row">
                  <div class="col-lg-10 col-sm-10 col-10">
                     <router-link to="/" class="" title="GUI Solutions Lanka (Pvt) Ltd | Home page">
                        <img class="d-block w-100 mb-4" src="../../../public/images/gui-logo-footer.png"
                           alt="GUI Solutions Lanka (Pvt) Ltd">
                     </router-link>
                  </div>
               </div>

               <p class="text-blue font-14 mb-3"><span class="fw-bold">GUI Solutions Lanka (Pvt) Ltd</span> is a global leader in software
                  development, delivering innovative solutions since 2011. We empower enterprises worldwide with
                  custom-built, cutting-edge software platforms.</p>
               <p class="text-blue font-14 mb-3 fw-bolder d-none d-xl-block">Copyright @ {{ currentYear }} All
                  rights reserved</p>
            </div>
            <div class="col-lg-3 col-sm-6">
               <p class="mb-lg-3 mb-2 text-dark font-20 text-uppercase text-blue pt-3 fw-500">CONTACT</p>
               <ul class="mt-4">
                  <li class="pb-4">
                     <div class="d-flex align-items-center">
                        <p
                           class="social d-flex justify-content-center align-items-center text-blue font-15 fw-500 me-2 px-2">
                           <i class="fas fa-map-marker-alt"></i>
                        </p>
                        <span class="font-15 fw-bold text-dark">No. 5E/67, Level-4, Kandy Road, Kadawatha, Sri
                           Lanka</span>
                     </div>
                  </li>
                  <li class="pb-4">
                     <div class="d-flex align-items-center">
                        <p
                           class="social d-flex justify-content-center p-lg-0 p-2 align-items-center text-blue font-15 fw-500 me-2">
                           <i class="fas fa-phone-alt"></i>
                        </p>
                        <span class="font-15 fw-bold text-dark">
                           <a href="tel:+94112922420" target="_blank" class="text-black-cus">+94 112922420</a><br>
                           <a href="tel:+94767079290" target="_blank" class="text-black-cus">+94 767079290 (Hotline)</a>
                        </span>
                     </div>
                  </li>
                  <li class="pb-4">
                     <div class="d-flex align-items-center">
                        <p
                           class="social d-flex justify-content-center align-items-center text-blue font-15 fw-500 me-2">
                           <i class="fas fa-envelope"></i>
                        </p>
                        <span class="font-15 fw-bold text-dark">info@guisrilanka.com</span>
                     </div>
                  </li>
               </ul>

            </div>
            <div class="col-lg-2 col-sm-6">
               <p class="mb-lg-3 mb-2 text-dark font-20 text-uppercase text-blue pt-3 fw-500">QUICK LINKS</p>
               <ul class="mt-4">
                  <li class="pb-lg-2 pb-1">
                     <router-link to="/blogs" class="font-14 text-dark hvr-forward fw-bold" @click="scrollToTop">
                        Blogs
                     </router-link>
                  </li>
                  <li class="pb-lg-2 pb-1">
                     <router-link to="/partners" class="font-14 text-dark hvr-forward fw-bold" @click="scrollToTop">
                        Become a Partner
                     </router-link>
                  </li>
                  <li class="pb-lg-2 pb-1">
                     <router-link to="/career" class="font-14 text-dark hvr-forward fw-bold" @click="scrollToTop">
                        Careers
                     </router-link>
                  </li>
                  <li class="pb-lg-2 pb-1">
                     <router-link to="/privacy-policy" class="font-14 text-dark hvr-forward fw-bold"
                        @click="scrollToTop">
                        Privacy Policy
                     </router-link>
                  </li>
               </ul>
            </div>
            <div class="col-lg-3 col-sm-6">
               <p class="mb-lg-3 mb-2 text-dark font-20 text-uppercase text-blue pt-3 fw-500">KEEP IN TOUCH</p>
               <ul class="mt-4">
                  <li class="pb-3">
                     <p class="font-14 text-dark fw-bold mb-1">Get latest news delivered daily!</p>
                     <p class="font-14 text-dark" @click="scrollToTop">
                        Subscribe to our newsletter
                     </p>
                  </li>
                  <li class="pb-0">
                     <div class="custom-input-group">
                        <form v-if="!loading" @submit.prevent="subscription()">
                           <input type="email" v-model="subscriptionData.email" class="form-control"
                              placeholder="Email address" name="email"
                              :class="{ 'is-invalid-custom': emailError, 'is-invalid': emailError }">
                           <div class="input-group-append">
                              <button class="btn" type="submit" aria-label="Submit Form">
                                 <i class="fas fa-angle-right"></i>
                              </button>
                           </div>
                        </form>
                        <div class="d-flex" v-else>
                           <div class="spinner-border m-2 text-blue" role="status">
                              <span class="visually-hidden">Loading...</span>
                           </div>
                        </div>
                     </div>
                  </li>
               </ul>
               <div class="d-flex gap-2 mb-4">
                  <a href="https://www.facebook.com/guisrilanka/"
                     class="social d-flex justify-content-center align-items-center" role="button"
                     aria-label="Visit Gui Solutions Lanka on Facebook" target="_blank">
                     <i class="fa-brands fa-facebook-f text-blue font-15 fw-500" aria-hidden="true"></i>
                  </a>
                  <a href="https://lk.linkedin.com/company/gui-solutions-lanka-pvt-ltd"
                     class="social d-flex justify-content-center align-items-center" role="button"
                     aria-label="Visit Gui Solutions Lanka on LinkedIn" target="_blank">
                     <i class="fa-brands fa-linkedin-in text-blue font-15 fw-500" aria-hidden="true"></i>
                  </a>
                  <a href="https://wa.me/94767079290" class="social d-flex justify-content-center align-items-center"
                     role="button" aria-label="Visit Gui Solutions Lanka on WhatsApp" target="_blank">
                     <i class="fa-brands fa-whatsapp text-blue font-18 fw-500" aria-hidden="true"></i>
                  </a>
               </div>
            </div>
            <p class="text-center my-2 fw-semibold font-14">Designed and Developed by <span
                  class="text-blue fw-bold">GUI Solutions Lanka Pvt. Ltd.</span></p>
            <div class="col-12 d-block d-xl-none">
               <p class="text-black-cus font-14 mb-3 fw-bolder text-center pt-3">Copyright @ {{ currentYear }} All
                  rights reserved</p>
            </div>
         </div>
      </div>
   </div>
</template>
<script>

import Swal from 'sweetalert2';

export default {
   name: 'AppFooter',
   data() {
      return {
         currentYear: new Date().getFullYear(),
         subscriptionData: {
            email: ''
         },
         emailError: false,
         loading: false,
      };
   },
   methods: {
      scrollToTop() {

         window.scrollTo({
            top: 0,
            behavior: 'smooth'
         });
      },
      async subscription() {
         this.loading = true;
         let email = this.subscriptionData.email;

         if (!email) {
            this.emailError = true;
            this.loading = false;
            return;
         }

         this.emailError = false;

         await this.$store.dispatch('subscription', { email })
            .then((response) => {
               this.loading = false;
               if (response === 1) {

                  this.subscriptionData.email = '';

                  Swal.fire({
                     icon: 'success',
                     title: 'Thank you for subscribing.',
                     text: 'We will keep you updated on the latest news and events.',
                  });
               } else {

                  this.subscriptionData.email = '';

                  Swal.fire({
                     icon: 'error',
                     title: 'Oops...',
                     text: 'Something went wrong!',
                  });
               }
            });
      }
   },

}
</script>

<style scoped>
.custom-input-group {
   position: relative;
   margin-bottom: 1rem;
   background-color: transparent;
}

.custom-input-group input {
   width: 100%;
   padding: 10px;
   border: none;
   border-bottom: 2px solid #ccc;
   border-radius: 0;
   outline: none;
   background-color: transparent;
}

.custom-input-group input.is-invalid-custom {
   border-bottom: 2px solid red;
}

.custom-input-group input:focus {
   border-bottom: 2px solid #007bff;
   box-shadow: none;
   background-color: transparent;
}

.custom-input-group .input-group-append {
   position: absolute;
   right: 0;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
}

.custom-input-group .input-group-append .btn {
   background-color: #563d7c;
   border: none;
   border-radius: 50%;
   padding: 0rem 0.5rem !important;
}

.custom-input-group .input-group-append .btn i {
   color: #fff;
}
</style>