<template>
    <div class="container-fluid pb-5 d-none" data-aos="fade-up" data-aos-duration="2000">
        <div class="container">
            <div class="row justify-content-center align-items-center" id="counting-section" ref="countingSection">
                <div class="col-lg-3 col-sm-6 col-10 mt-3 mt-lg-0" v-if="visible">
                    <div class="row align-items-center">
                        <div class="col-lg-2 col-sm-2 col-2 pe-1">
                            <img class="d-block w-100" src="../../../public/images/icon-1-1.png"
                                alt="GUI Solutions Lanka (Pvt) Ltd">
                        </div>
                        <div class="col-lg-10 col-9">
                            <h4 class="fw-bold font-30 text-blue">{{ count1 }} +</h4>
                            <p class="fw-bold font-16 text-dark text-uppercase">Projects</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 mt-3 mt-lg-0" v-if="visible">
                    <div class="row align-items-center">
                        <div class="col-lg-2 col-sm-2 col-2 pe-1">
                            <img class="d-block w-100" src="../../../public/images/icon-1-2.png"
                                alt="GUI Solutions Lanka (Pvt) Ltd">
                        </div>
                        <div class="col-lg-10 col-9">
                            <h4 class="fw-bold font-30 text-blue">{{ count2 }} +</h4>
                            <p class="fw-bold font-16 text-dark text-uppercase">Satisfied Customers</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 mt-3 mt-lg-0" v-if="visible">
                    <div class="row align-items-center">
                        <div class="col-lg-2 col-sm-2 col-2 pe-1">
                            <img class="d-block w-100" src="../../../public/images/icon-1-3.png"
                                alt="GUI Solutions Lanka (Pvt) Ltd">
                        </div>
                        <div class="col-lg-10 col-9">
                            <h4 class="fw-bold font-30 text-blue">{{ count3 }} +</h4>
                            <p class="fw-bold font-16 text-dark text-uppercase">countries</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 mt-3 mt-lg-0" v-if="visible">
                    <div class="row align-items-center">
                        <div class="col-lg-2 col-sm-2 col-2 pe-1">
                            <img class="d-block w-100" src="../../../public/images/icon-1-4.png"
                                alt="GUI Solutions Lanka (Pvt) Ltd">
                        </div>
                        <div class="col-lg-10 col-9">
                            <h4 class="fw-bold font-30 text-blue">{{ count4 }} +</h4>
                            <p class="fw-bold font-16 text-dark text-uppercase">Awards Winning</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import { nextTick } from 'vue';

export default {
    name: 'CountingSection',
    data() {
        return {
            count1: 0,
            count2: 0,
            count3: 0,
            count4: 0,
            visible: false // Set visible to false initially
        }
    },
    mounted() {
        nextTick(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.visible = true;
                        this.startCounting();
                        observer.unobserve(entry.target);
                    }
                });
            });
            observer.observe(this.$refs.countingSection);
        });
    },
    methods: {
        startCounting() {
            this.count("count1", 0, 400, 3000);
            this.count("count2", 100, 150, 2500);
            this.count("count3", 0, 40, 3000);
            this.count("count4", 50, 140, 3000);
        },
        count(id, start, end, duration) {
            let current = start;
            const range = end - start;
            const increment = end > start ? 1 : -1;
            const step = Math.abs(Math.floor(duration / range));
            const timer = setInterval(() => {
                current += increment;
                this[id] = current;
                if (current === end) {
                    clearInterval(timer);
                }
            }, step);
        },
    },
}
</script>
