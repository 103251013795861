// store/careers.js
import axiosClient from '@/axios';

const careersData = {
  namespaced: true,
  state() {
    return {
      careersData: {},
      testimonialData: {},
      singleCareer: null,
      error: null
    };
  },
  getters: {},
  mutations: {
    setCareers(state, payload) {
      state.careersData = payload;
    },
    setSingleCareer(state, payload) {
      state.singleCareer = payload;
    },
    setTestimonials(state, payload) {
      state.testimonialData = payload;
    },
    setError(state, error) {
      state.error = error;
    }
  },

  actions: {
    async getCareers({ commit }) {

      return axiosClient
        .get('/careers')
        .then(async (response) => {
          if (response.status == 200) {
            await commit("setCareers", response.data.payload);

            return 1;
          } else {
            commit("setError", 'error from server');
            return 0;
          }
        })
        .catch((error) => {
          console.error(error);
          commit("setError", error);
        });
    },

    async getSingleCareer({ commit }, { route }) {

      return axiosClient
        .get(`/careers/get/${route.slug}`)
        .then(async (response) => {
          if (response.status == 200) {
            await commit("setSingleCareer", response.data.payload);

            return 1;
          } else {
            commit("setError", 'error from server');
            return 0;
          }
        })
        .catch((error) => {
          console.error(error);
          commit("setError", error);
        });
    },

    //fetch all testimonials
    async getTestimonials({ commit }) {

      return axiosClient
        .get('/testimonials')
        .then(async (response) => {
          if (response.status == 200) {
            
            const statusOneData = response.data.payload.filter(item => item.status === 1);
            await commit("setTestimonials", statusOneData);
            
            return 1;
          } else {
            commit("setError", 'error from server');
            return 0;
          }
        })
        .catch((error) => {
          console.error(error);
          commit("setError", error);
        });
    },

    // careersData.js (Vuex store module)
    async submitCareer({ commit }, { first_name, last_name, email, phone, pdf, career_id, token }) {

      let formData = {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'phone': phone,
        'pdf': pdf,
        'career_id': career_id,
        'g-recaptcha-response': token
      }

      return axiosClient
        .post('/careers/save', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {

          if (response.data.status === true) {
            return 1;

          } else {

            return 0;
          }
        })
        .catch((error) => {
          console.error(error);
          commit('setError', error);
          return error;
        });
    }
  }
};
export default careersData;