import axios from 'axios';
import store from '@/store';
const axiosClient = axios.create({
    baseURL: 'https://admin.guisrilanka.com/api',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.request.use(config => {
    const token = store.state.token;
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    } else {
        console.warn('No token found in Vue store.');
    }
    return config;
});

export default axiosClient;