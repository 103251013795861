<template>
    <!-- <canvas id="world" width="auto" height="auto"></canvas> -->
    <div class="fixed-top" id="header" :class="{ 'scrolled': isScrolled }">
        <div class="container-fluid header-section py-lg-2 border-color-cus">
            <div class="container pt-lg-2 py-2">
                <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-7 col-8 webiiste-logo">
                        <router-link to="/" class="" @click="scrollToTop"
                            title="GUI Solutions Lanka (Pvt) Ltd | Home page">
                            <img class="d-block w-100" src="../../../public/images/logo.png"
                                alt="GUI Solutions Lanka (Pvt) Ltd">
                        </router-link>
                    </div>
                    <div class="col-lg-9 col-4">

                        <nav class="navbar navbar-expand-lg py-0 d-lg-block d-none">
                            <div class="collapse navbar-collapse justify-content-end pt-1" id="navbar-content">
                                <ul class="navbar-nav nav-custom">
                                    <li class="nav-item pe-lg-4">
                                        <router-link to="/" active-class="active" @click="scrollToTop"
                                            class="nav-link text-white font-15 fw-bolder text-blue text-uppercase">Home</router-link>
                                    </li>
                                    <li class="nav-item pe-lg-4">
                                        <router-link to="/about-us" active-class="active" @click="scrollToTop"
                                            class="nav-link text-white font-15 fw-bolder text-blue text-uppercase">About
                                            Us</router-link>
                                    </li>
                                    <li class="nav-item dropdown pe-lg-4">
                                        <a class="nav-link text-white dropdown-toggle font-15 fw-bolder text-blue text-uppercase custom-dropdown-toggle"
                                            href="#" aria-expanded="false"
                                            :class="{ 'active router-link-exact-active': isActive }">Our Solutions</a>
                                        <ul class="dropdown-menu shadow no-arrow custom-dropdown-menu custom-dropdown-aling">
                                            <template v-if="$store.state.categoryData.categoryData.length > 0">
                                                <li v-for="category in $store.state.categoryData.categoryData"
                                                    :key="category.id" class="dropdown dropend custom-dropdown-item">
                                                    <template v-if="category.subCategories.length > 0">
                                                        <router-link :to="`/${category.slug}`"
                                                            class="dropdown-item dropdown-toggle no-arrow custom-dropdown-toggle"
                                                            active-class="active" @click="scrollToTop">
                                                            {{ category.name }}
                                                        </router-link>
                                                        <ul class="dropdown-menu shadow no-arrow custom-dropdown-menu">
                                                            <li v-for="subCategory in category.subCategories"
                                                                :key="subCategory.id">
                                                                <router-link
                                                                    :to="{ path: `/solutions/${subCategory.slug}` }"
                                                                    class="dropdown-item custom-dropdown-item"
                                                                    active-class="active" @click="scrollToTop">
                                                                    {{ subCategory.name }}
                                                                </router-link>
                                                            </li>
                                                        </ul>
                                                    </template>
                                                    <template v-else>
                                                        <router-link :to="`/${category.slug}`"
                                                            class="dropdown-item custom-dropdown-item"
                                                            active-class="active" @click="scrollToTop">
                                                            {{ category.name }}
                                                        </router-link>
                                                    </template>
                                                </li>
                                            </template>
                                        </ul>
                                    </li>
                                    <li class="nav-item pe-2">
                                        <router-link to="/contact-us" active-class="active"
                                            class="nav-link text-white font-15 fw-bolder text-blue text-uppercase"
                                            @click="scrollToTop">Contact
                                            Us</router-link>
                                    </li>
                                    <li class="nav-item ps-lg-5 text-lg-end text-center last-cus">
                                        <router-link to="/request-call" @click="scrollToTop"
                                            class="hvr-wobble-horizontal font-15 fw-bolder text-blue rounded-pill px-4 py-2 border-blue text-uppercase custom-request-call">
                                            Request a Call
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                        <!-- Offcanvas Navbar Toggle Button -->
                        <button class="navbar-toggler d-lg-none d-block ms-auto" type="button"
                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                            aria-label="Toggle navigation">
                            <i
                                class="fas fa-bars text-black font-22 px-2 py-1 rounded border border-black border-2"></i>
                        </button>

                        <!-- Offcanvas Navbar -->
                        <div class="offcanvas offcanvas-end bg-blue" tabindex="-1" id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title d-none" id="offcanvasNavbarLabel">Menu</h5>
                                <button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <!-- Navbar Links -->
                                <nav class="navbar-nav">
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <router-link to="/" @click="clickCloseBtn"
                                                active-class="active text-blue bg-white px-2 rounded my-1"
                                                class="nav-link text-white text-uppercase">Home</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/about-us" @click="handleLinkClick"
                                                active-class="active text-blue bg-white px-2 rounded my-1"
                                                class="nav-link text-white text-uppercase">About
                                                Us</router-link>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle text-uppercase"
                                                :class="{ 'text-blue bg-white px-2 rounded my-1': isActive === true, 'text-white': isActive === false }"
                                                href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                Our Solutions
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <template v-if="this.$store.state.categoryData.categoryData.length > 0">
                                                    <li v-for="category in this.$store.state.categoryData.categoryData"
                                                        :key="category.id" class="dropdown">
                                                        <template v-if="category.subCategories.length > 0">
                                                            <router-link :to="`/${category.slug}`"
                                                                @click="handleLinkClick" class="dropdown-item">
                                                                {{ category.name }}
                                                            </router-link>
                                                        </template>
                                                        <template v-else>
                                                            <router-link @click="handleLinkClick"
                                                                :to="`/${category.slug}`"
                                                                class="dropdown-item">
                                                                {{ category.name }}
                                                            </router-link>
                                                        </template>
                                                    </li>
                                                </template>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/contact-us"
                                                active-class="active text-blue bg-white px-2 rounded my-1"
                                                class="nav-link text-white text-uppercase"
                                                @click="handleLinkClick">Contact Us</router-link>
                                        </li>
                                        <li class="nav-item text-center">
                                            <router-link to="/request-call"
                                                class="nav-link text-blue btn bg-white rounded-pill px-4 py-2 text-uppercase fw-bold"
                                                @click="handleLinkClick">
                                                Request a Call
                                            </router-link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    name: "AppHeader",
    data() {
        return {
            sidenavWidth: '0',
            isScrolled: false,
            categories: [],
            isActive: true,
        };
    },
    watch: {
        '$route': 'checkSlugs'
    },
    methods: {
        handleScroll() {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            this.isScrolled = scrollTop > 100;
        },
        openNav() {
            if (window.matchMedia("(max-width: 600px)").matches) {
                this.sidenavWidth = '250px';
            } else {
                this.sidenavWidth = '500px';
            }
        },
        closeNav() {
            this.sidenavWidth = '0';
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },

        clickCloseBtn() {
            $('.btn-close').click();
        },
        handleLinkClick() {
            this.scrollToTop();
            this.clickCloseBtn();
        },
        checkSlugs() {
            const currentPathSlug = this.$route.path.split('/').pop();
            const categoryData = this.$store.state.categoryData.categoryData;

            let category = categoryData.find(category => category.slug === currentPathSlug);

            if (category) {
                this.isActive = true;
            } else {
                category = categoryData.find(category => {
                    if (category.subCategories) {
                        return category.subCategories.find(subCategory => subCategory.slug === currentPathSlug);
                    }
                    return false;
                });

                this.isActive = !!category;
            }
        }

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.checkSlugs();
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>


<style>
.border-color-cus {
    border-bottom: 1px solid #a7a7a7 !important;
}

.custom-dropdown-aling{
    position: relative;
    right: 10px;

}

.no-arrow::after {
    display: none !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.scrolled {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-nav .dropdown:hover>.custom-dropdown-menu {
    display: block;
}

.navbar-nav .dropdown:hover>.custom-dropdown-toggle::after {
    transform: rotate(180deg);
}

.navbar-nav .custom-dropdown-menu {
    margin-top: 0;
    padding: 0 !important;
}

.dropend .custom-dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

.custom-dropdown-menu.show {
    -webkit-animation: fadeIn 0.3s alternate;
    animation: fadeIn 0.3s alternate;
}

.text-uppercase {
    text-transform: uppercase !important;
}
</style>
