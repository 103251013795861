<template>
  <LoaderIcon :loading="isLoading" v-if="isLoading" />
  <div v-else class="custom-background-gradient">
    <AppHeader />
    <div class="footerbg-img">
      <router-view />
    </div>
    <AppFooter />
    <WhatsAppButton />
    <ScrollToTopButton />
    <CookiePolicyAlert />
  </div>
</template>

<script>
import AppHeader from './components/headers/AppHeader.vue';
import AppFooter from '../src/components/footers/AppFooter.vue';
import CookiePolicyAlert from '../src/components/cookiesPolicy/CookiePolicyAlert.vue';
import ScrollToTopButton from '../src/components/ScrollToTopButton.vue';
import LoaderIcon from '../src/components/icons/loader-icon.vue';
import WhatsAppButton from '../src/components/WhatsAppButton.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
    CookiePolicyAlert,
    ScrollToTopButton,
    LoaderIcon,
    WhatsAppButton
  },
  data() {
    return {
      isLoading: false
    };
  },
  async created() {
    await this.setToken();
    await this.getSiteKey();
    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.setCategories();
      await this.getProjects();
    },
    async setToken() {
      this.isLoading = true;
      // if (this.$store.state.token == null) {
        await this.$store.dispatch("getToken");
      // }
    },
    async getSiteKey() {
      this.isLoading = true;
      if (!this.$store.state.recaptchaToken) {
        await this.$store.dispatch("getRecaptchaToken");
      }
    },
    async setCategories() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("categoryData/getCategories");
        this.handleResponse(response);
      } catch (error) {
        this.isLoading = false;
        console.error("Category response error:", error);
      }
    },
    async getProjects() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("categoryData/getProjects");
        this.handleResponse(response);
      } catch (error) {
        this.isLoading = false;
        console.error("Projects response error:", error);
      }
    },
    handleResponse(response) {
      this.isLoading = response !== 1;
    },
  }
};
</script>

<style scoped>
/* Your scoped styles here */
</style>
