import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import OwlCarousel from 'vue-owl-carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueReCaptcha from 'vue3-recaptcha2';

import '../public/css/web-custom-N.css';
import '../public/css/web-custom-responsive-N.css';
import '../public/css/web-custome-p.css';
import '../public/css/web-custome-p-responsive-p.css';
import '../public/css/web-custom-C.css';

const app = createApp(App);

app.use(store); app.use(router); app.use(OwlCarousel); app.component('vue3-recaptcha2', VueReCaptcha); app.mount('#app');

AOS.init();
