import axiosClient from '@/axios'; // Assuming axios.js is in the src folder

const inquiryForm = {
  namespaced: true,
  state() {
    return {
      formMsg: null
    }
  },

  mutations: {
    setFormMessage(state, payload) {
      state.formMsg = payload;
    }
  },

  actions: {

    async inquirySubmitForm({ commit }, { name, email, phone_number, inquiry_type_id, message, request_method, token }) {

      let formData = {
        'name': name,
        'email': email,
        'phone_number': phone_number,
        'inquiry_type_id': inquiry_type_id,
        'message': message,
        'request_method': request_method,
        'g-recaptcha-response': token
      }

      let url = "/inquiry";

      return axiosClient
        .post(url, formData)
        .then((response) => {
          if (response.data.status == true) {
            name = "";
            email = "";
            phone_number = "";
            inquiry_type_id = "";
            message = "";
            request_method = "";
            token = "";

            commit("setFormMessage", response.data.message);
            return [1, response.data.message];

          } else {
            commit("setFormMessage", response.data.message);
            return [0, response.data.message];
          }
        }).catch(error => {
          commit("setFormMessage", error);
        });
    },

    async partnerSubmitForm({ commit }, { name, title, email, phone_number, company, website, details, token }) {

      let formDataPatner = {
        'name': name,
        'title': title,
        'email': email,
        'phone_number': phone_number,
        'company': company,
        'website': website,
        'details': details,
        'g-recaptcha-response': token
      }

      let url = "/partner";

      return axiosClient
        .post(url, formDataPatner)
        .then((response) => {
          if (response.data.status == true) {
            name = "";
            title = "";
            email = "";
            phone_number = "";
            company = "";
            website = "";
            details = "";
            token = "";

            commit("setFormMessage", response.data.message);
            return [1, response.data.message];

          } else {
            commit("setFormMessage", response.data.message);
            return [0, response.data.message];
          }
        }).catch(error => {
          commit("setFormMessage", error);
        });
    }
  },



  getters: {
    formMessage(state) {
      return state.formMsg;
    }
  },
};

export default inquiryForm;

