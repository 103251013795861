<template>
    <a class='blantershow-chat bounce' target="_blank" href="https://wa.me/94767079290" title='Show Chat'>
        <svg width="30" viewBox="0 0 24 24">
            <defs />
            <path fill="#eceff1"
                d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z" />
            <path fill="#4caf50"
                d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z" />
            <path fill="#fafafa"
                d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z" />
        </svg>
        Chat with Us
    </a>
    <!-- <a href="https://wa.me/94767079290" class="float bounce" target="_blank">
        <i class="fa-brands fa-whatsapp my-float"></i>
    </a> -->
</template>

<script>

export default {
    name: "WhatsAppButton"
}
</script>

<style scoped>
.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 70px;
    right: 10px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

.bounce {
    animation: bounce 2s infinite;
}

@keyframes bounce {

    0%,
    25%,
    50%,
    75%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-12px);
    }
}
</style>

<style scope>
.blantershow-chat {
    position: fixed;
    top: 90%;
    right: 0;
    padding-left: 20px;
    background-color: #ffffffb0;
    margin-right: 65px;
}


a:link,
a:visited {
    color: #444;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
}



a.blantershow-chat {
    /* / background: #009688;/ background: #fff; */
    color: #404040;
    position: fixed;
    display: flex;
    font-weight: 400;
    justify-content: space-between;
    align-items: center;
    z-index: 98;
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 30px;
    box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
}

a.blantershow-chat svg {
    transform: scale(1.2);
    margin: 0 10px 0 0;
}
</style>