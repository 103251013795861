<template>
   <LoaderIcon :loading="isLoading" />
   <!-- AppSlider -->
   <AppSlider/>
   <!-- About us Section -->
   <!-- Desktop -->
   <div class="container-fluid pt-3 pb-5 service-section d-none d-lg-block" data-aos="fade-up" data-aos-duration="2000">
      <div class="container pt-lg-5">
         <div class="row align-items-center">
            <div class="col-lg-6 col-12 mb-3">
               <div class="row align-items-center">
                  <!-- <div class="col-5 d-xl-block d-none">
                     <img src="../../../public/images/global-locations.png" class="about-one-img rounded-15"
                        alt="GUI Solutions Lanka (Pvt) Ltd">
                  </div>
                  <div class="col-xl-6 col-12">
                     <img src="../../../public/images/global-locations.png" class="about-two-img rounded-15"
                        alt="GUI Solutions Lanka (Pvt) Ltd">
                  </div> -->
                  <div class="col-12 d-xl-block d-none">
                     <img src="../../../public/images/app/gif.gif" class="w-100 rounded-15"
                        alt="GUI Solutions Lanka (Pvt) Ltd">
                  </div>
               </div>
            </div>
            <div class="col-lg-6 col-12">
               <h3 class="font-36 mb-2 text-blue fw-bold">
                  Who We Are
               </h3>
               <h1 class="font-20 lh-base text-blue fw-bold mb-1">Welcome to GUI Solutions Lanka!</h1>
               <p class="font-18 lh-base text-dark mb-3">GUI Solutions Lanka (Pvt) Ltd, a global software development
                  company
                  in
                  Sri Lanka, has provided software solutions to enterprises worldwide since 2011. We build powerful
                  corporations by anticipating industry changes and reacting quickly to achieve maximum benefits for
                  every
                  entity in the industry.</p>
               <router-link to="/about-us" @click="scrollToTop"
                  class="fw-bolder text-white btn border-0 font-16 py-lg-2 py-2 bg-blue1 text-center rounded-pill btn-cus-gradient hvr-shrink">
                  Find More Out
               </router-link>
            </div>
         </div>
      </div>
   </div>
   <!-- Mobile -->
   <div class="container-fluid pt-3 pb-5 service-section d-lg-none d-block" data-aos="fade-up" data-aos-duration="2000">
      <div class="container pt-lg-5">
         <div class="row align-items-center flex-row-reverse">

            <div class="col-lg-6 col-12 mb-3">
               <h3 class="font-36 mb-2 text-blue fw-bold">
                  Who We Are
               </h3>
               <p class="font-20 lh-base text-blue fw-bold mb-1">Welcome to GUI Solutions Lanka!</p>
               <p class="font-18 lh-base text-dark mb-3">GUI Solutions Lanka (Pvt) Ltd, a global software development
                  company
                  in
                  Sri Lanka, has provided software solutions to enterprises worldwide since 2011. We build powerful
                  corporations by anticipating industry changes and reacting quickly to achieve maximum benefits for
                  every
                  entity in the industry.</p>
               <router-link to="/about-us" @click="scrollToTop"
                  class="fw-bolder text-white btn border-0 font-16 py-lg-2 py-2 bg-blue1 text-center rounded-pill btn-cus-gradient hvr-shrink">
                  Find Out More
               </router-link>
            </div>
            <div class="col-lg-6 col-12">
               <div class="row gap-3 align-items-center">
                  <!-- <div class="col-5 d-lg-block d-none">
                     <img src="../../../public/images/global-locations.png" class="about-one-img rounded-15"
                        alt="GUI Solutions Lanka (Pvt) Ltd">
                  </div>
                  <div class="col-lg-6 col-12">
                     <img src="../../../public/images/global-locations.png" class="about-two-img rounded-15"
                        alt="GUI Solutions Lanka (Pvt) Ltd">
                  </div> -->
                  <img src="../../../public/images/app/gif.gif" class="w-100 rounded-15"
                        alt="GUI Solutions Lanka (Pvt) Ltd">
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Counting -->
   <AppCounting/>

   <!-- Project Section -->
   <ProjectSection />

   <!-- Request section -->
   <div class="container-fluid mines-mt-3 mt-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
      data-aos-duration="2000">
      <div class="container">
         <div class="row align-items-center">
            <div class="col-lg-7 col-sm-12 py-5">
               <h2 class="text-blue font-36 fw-bold pt-5 pb-2">Request a Call Back
               </h2>
               <form action="" method="post" @submit.prevent="submitForm">
                  <input type="hidden" v-model="inputData.request_method" name="request_method" value="Inquiry">
                  <div class="row pt-3">
                     <div class="col-lg-6">
                        <select class="form-select py-3 text-dark fw-bolder font-14 rounded-3 border-muted "
                           aria-label="Default select example" v-model="inputData.inquiry_type_id" required
                           :class="{ 'is-invalid': invalidFields.includes('inquiry_type_id') }">
                           <option value="" disabled selected>How can we help:*</option>
                           <option value="1">Mobile App Development</option>
                           <option value="2">ERP Consultancy</option>
                           <option value="3">eCommerce and Web Development</option>
                           <option value="4">Business Software Solution</option>
                           <option value="5">Become A Partner</option>
                           <option value="6">General</option>
                        </select>
                     </div>
                     <div class="col-lg-6 pt-lg-0 pt-3">
                        <input class="form-control py-3 h-55 text-dark fw-bolder font-14 rounded-3 border-muted"
                           type="email" placeholder="Email:*" aria-label="default input example"
                           v-model="inputData.email" required name="email"
                           :class="{ 'is-invalid': invalidFields.includes('email') }">
                     </div>
                     <div class="col-lg-6 pt-3">
                        <input class="form-control py-3 h-55 text-dark fw-bolder font-14 rounded-3 border-muted"
                           type="text" placeholder="Phone Number:*" aria-label="default input example"
                           v-model="inputData.phone_number" required @keyup="validatePhoneNumber" name="phone_number"
                           :class="{ 'is-invalid': invalidFields.includes('phone_number') || phoneNumberError }">
                        <div v-if="phoneNumberError" class="invalid-feedback">
                           {{ phoneNumberErrorMessage }}
                        </div>
                     </div>
                     <div class="col-lg-6 pt-3">
                        <input class="form-control py-3 h-55 text-dark fw-bolder font-14 rounded-3 border-muted"
                           type="text" placeholder="Your Name:*" aria-label="default input example" name="name"
                           v-model="inputData.name" required :class="{ 'is-invalid': invalidFields.includes('name') }">
                     </div>
                     <div class="col-12 pt-4">
                        <textarea class="form-control py-3 h-55 text-dark fw-bolder font-14 rounded-3 border-muted"
                           type="text" placeholder="Message:*" aria-label="default input example" rows="3"
                           name="message" v-model="inputData.message" required
                           :class="{ 'is-invalid': invalidFields.includes('message') }"></textarea>
                     </div>

                     <div v-if="isLoading"></div>
                     <div class="col-12 px-lg-3 pt-4" v-else>
                        <VueReCaptcha v-if="showRecaptcha" :sitekey="siteKeyToken" size="normal" theme="light" hl="tr"
                           @verify="onVerify" @expire="recaptchaExpired" @fail="recaptchaFailed" @error="recaptchaError"
                           @click="recaptchaClicked" ref="vueRecaptcha" />

                        <div class="text-danger" v-if="recaptchaErrorOccurred">{{ captchaError }}</div>
                     </div>

                     <div class="col-12 px-lg-3 pt-4">
                        <button
                           class="btn hvr-shrink btn-danger rounded-pill text-start mt-2 me-2 px-5 text-white font-14 fw-bold float-lg-start rounded-padding"
                           v-if="recaptchaErrorOccurred" @click="retryRecaptcha">Retry</button>
                        <button v-else
                           class="btn hvr-shrink btn-cus-gradient rounded-pill px-5 text-white font-16 fw-bold float-lg-start rounded-padding">
                           Submit
                        </button>
                     </div>
                  </div>
               </form>
            </div>
            <div class="col-lg-5 col-12 text-sm-center d-block mb-5 mb-lg-0">
               <img class="d-block w-100 " src="../../../public/images/app/contact.svg" alt="GUI Solutions Lanka (Pvt) Ltd"
                  data-aos="fade-up" data-aos-duration="2000">
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import 'jquery';
import 'owl.carousel/dist/owl.carousel.min';
import LoaderIcon from "../../components/icons/loader-icon.vue";
import Swal from 'sweetalert2';
import ProjectSection from '../../components/project/ProjectSection.vue';
// import { nextTick } from 'vue';
import VueReCaptcha from 'vue3-recaptcha2';
import AppSlider from '@/components/sliders/AppSlider.vue';
import AppCounting from '@/components/countingSection/AppCounting.vue';

export default {
   name: 'HomeView',
   components: {
      LoaderIcon,
      ProjectSection,
      VueReCaptcha,
      AppSlider,
      AppCounting
   },
   data() {
      return {
         inputData: {
            name: '',
            email: '',
            phone_number: '',
            inquiry_type_id: '',
            message: '',
            request_method: 'Inquiry'
         },
         isLoading: false,
         message: '',
         isError: false,
         invalidFields: [],
         phoneNumberError: false,
         phoneNumberErrorMessage: '',
         showRecaptcha: true,
         recaptchaErrorOccurred: false,
         captchaError: '',
         recaptchaToken: '',
         siteKeyToken: '',
         pageTitle: '',
         metaDescription: '',
         metaKeywords: '',
         canonicalUrl: '',
      };
   },
   created() {
      this.$store.dispatch("careersData/getCareers");
      this.getSiteKey();

      this.pageTitle = 'Software solutions | Web design service - GUI Solutions Sri Lanka';
      this.metaDescription = 'GUI offers the high-quality software solutions especially focusing on our customers to take their businesses to the next level. We are proud to be leading hrm software solutions provider and best software development company in Sri Lanka entirely due to the dedication of our Software engineers and other staff.';
      this.metaKeywords = 'Software solutions | Web design service - GUI Solutions Sri Lanka';
      this.canonicalUrl = 'https://guisrilanka.com/';

      document.title = this.pageTitle;
      this.updateMetaTags();
   },

   computed: {
      categories() {
         return this.$store.state.categoryData.categoryData;
      },
   },
   methods: {

      updateMetaTags() {
         let descriptionTag = document.querySelector('meta[name="description"]');
         let keywordsTag = document.querySelector('meta[name="keywords"]');
         let canonicalUrlTag = document.querySelector('link[rel="canonical"]');

         if (!descriptionTag) {
            descriptionTag = document.createElement('meta');
            descriptionTag.setAttribute('name', 'description');
            document.head.appendChild(descriptionTag);
         }
         descriptionTag.setAttribute('content', this.metaDescription);

         if (!keywordsTag) {
            keywordsTag = document.createElement('meta');
            keywordsTag.setAttribute('name', 'keywords');
            document.head.appendChild(keywordsTag);
         }
         keywordsTag.setAttribute('content', this.metaKeywords);

         if (!canonicalUrlTag) {
            canonicalUrlTag = document.createElement('link');
            canonicalUrlTag.setAttribute('rel', 'canonical');
            document.head.appendChild(canonicalUrlTag);
         }

         canonicalUrlTag.setAttribute('href', this.canonicalUrl);
      },
      async getSiteKey() {
         if (!this.$store.state.recaptchaToken) {
            this.isLoading = true;
            await this.$store.dispatch("getRecaptchaToken");
            this.isLoading = false;
            this.siteKeyToken = this.$store.state.recaptchaToken;
         } else {
            this.siteKeyToken = this.$store.state.recaptchaToken;
         }
      },

      scrollToTop() {

         window.scrollTo({
            top: 0,
            behavior: 'smooth'
         });
      },

      onVerify(recaptchaToken) {
         this.recaptchaToken = recaptchaToken;
         this.recaptchaErrorOccurred = false;
         this.captchaError = '';
      },
      recaptchaExpired() {
         this.$refs.vueRecaptcha.reset();
         this.recaptchaErrorOccurred = true;
         this.captchaError = 'Captcha expired. Please try again.';
      },
      recaptchaFailed() {
         this.recaptchaErrorOccurred = true;
         this.captchaError = 'Captcha verification failed. Please try again.';
      },
      recaptchaError() {
         this.recaptchaErrorOccurred = true;
         this.captchaError = 'Captcha verification failed. Please try again.';
      },
      recaptchaClicked() {
         this.captchaError = '';
         this.recaptchaErrorOccurred = false;
         return true;
      },
      retryRecaptcha() {
         this.recaptchaErrorOccurred = false;
         this.showRecaptcha = false;
         this.$nextTick(() => {
            this.showRecaptcha = true;
         });
      },

      validatePhoneNumber(event) {
         const input = event.target;
         input.value = input.value.replace(/[^0-9]/g, '');
         this.inputData.phone_number = input.value;
         this.inputData.postal_code = input.value;
      },
      validationFormData() {
         const { name, email, phone_number, inquiry_type_id, message } = this.inputData;
         this.invalidFields = [];

         if (!name) {
            this.invalidFields.push('name');
         }
         if (!email) {
            this.invalidFields.push('email');
         }


         if (!phone_number) {
            this.invalidFields.push('phone_number');
         } else {
            const phoneNumber = parseInt(this.inputData.phone_number);
            if (phoneNumber > 1000000000 || phoneNumber < 100000000) {
               this.phoneNumberErrorMessage = "Please enter a valid phone number.";
               this.phoneNumberError = true;
               this.invalidFields.push('phone_valid');
            } else {
               this.phoneNumberErrorMessage = "";
               this.phoneNumberError = false;
            }

            // check valid phone number
            if (!this.inputData.phone_number.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im)) {
               this.invalidFields.push('phone_valid');
               this.phoneNumberErrorMessage = "Please enter a valid phone number.";
               this.phoneNumberError = true;
            } else {
               this.phoneNumberErrorMessage = "";
               this.phoneNumberError = false;
            }
         }


         if (!inquiry_type_id) {
            this.invalidFields.push('inquiry_type_id');
         }
         if (!message) {
            this.invalidFields.push('message');
         }

         return this.invalidFields.length === 0;
      },

      submitForm() {

         if (!this.validationFormData()) {
            return;
         }

         if (!this.recaptchaClicked()) {
            return;
         }

         if (!this.recaptchaToken) {
            this.recaptchaErrorOccurred = true;
            this.captchaError = "Captcha verification failed. Please try again.";
            // console.log(this.captchaError, this.recaptchaErrorOccurred);
            return;
         }

         this.isLoading = true;
         let name = this.inputData.name;
         let email = this.inputData.email;
         let phone_number = this.inputData.phone_number;
         let inquiry_type_id = this.inputData.inquiry_type_id;
         let message = this.inputData.message;
         let request_method = this.inputData.request_method;
         let token = this.recaptchaToken;

         this.$store.dispatch("inquiryForm/inquirySubmitForm", { name, email, phone_number, inquiry_type_id, message, request_method, token })
            .then((response) => {
               if (response[0] == 1) {
                  this.isLoading = false;
                  this.inputData = {
                     name: '',
                     email: '',
                     phone_number: '',
                     inquiry_type_id: '',
                     message: '',
                  };

                  // Reset recaptcha
                  this.recaptchaErrorOccurred = false;
                  this.captchaError = '';
                  this.recaptchaToken = '';

                  this.message = "Your inquiry has been successfully submitted.";
                  this.isError = false;

                  // Success
                  Swal.fire({
                     icon: 'success',
                     title: 'Success!',
                     text: this.message,
                  });

               } else {
                  this.isLoading = false;

                  // Reset recaptcha
                  this.$refs.vueRecaptcha.reset();
                  this.recaptchaErrorOccurred = false;
                  this.captchaError = '';
                  this.recaptchaToken = '';

                  this.message = 'Error submitting the form. Please try again.';
                  this.isError = true;
                  // error
                  Swal.fire({
                     icon: 'error',
                     title: 'Error!',
                     text: this.message,
                  });
               }
            })
            .catch((error) => {

               // Reset recaptcha
               this.$refs.vueRecaptcha.reset();
               this.recaptchaErrorOccurred = false;
               this.captchaError = '';
               this.recaptchaToken = '';

               this.isLoading = false;
               console.error("Inquiry response error:", error);
               this.message = `Error submitting the form: ${error.message}`;
               this.isError = true;

               // error
               Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: this.message,
               });
            });

      },
   }


};
</script>

<style>
.iconTransition {
   transition: all 0.5s ease-in-out;
}

.req-img-position {
   position: relative;
   top: 40px;
}

.custom-height {
   height: 440px;
}

.custom-height .carousel-inner {
   height: 100%;
}

.rounded-padding {
   padding: 10px 20px;
}

.carousel-control-next .cus,
.carousel-control-prev .cus {
   padding: 0 .5rem !important;
}

.border-space {
   padding: 5px 3px !important;
}

.about-two-img {
   width: 100%;
   height: 465px;
   object-fit: cover;
}

.about-one-img {
   width: 100%;
   height: 390px;
   object-fit: cover;
}

.btn-cus-width {
   width: 25%;
}

@media only screen and (max-width:767px) and (min-width: 576px) {
   .custom-height {
      height: 450px;
   }

   .custome-position-services {
      top: 50px !important;
      margin-bottom: 50px;
   }
}

@media only screen and (max-width: 576px) {
   .custom-height {
      height: 360px;
   }
}

@media only screen and (max-width:767px) {
   .btn-cus-width {
      width: 100%;
   }

   .mobile-position-cus {
      position: relative;
      left: 10px !important;
   }

   .slider-img-cus {
      width: 40%;
   }

   .custome-position {
      top: -75px;
   }

   .carousel-item {
      top: -25px;
   }
}

@media only screen and (max-width: 1024px) and (min-width: 991px) {
   .carousel-item {
      top: 0px;
   }
}

@media only screen and (max-width: 993px) and (min-width: 768px) {
   .carousel-item {
      top: 160px;
   }

   .custom-height {
      height: 540px;
   }

   .slider-img-cus {
      width: 40%;
   }
}
</style>