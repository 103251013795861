<template>
    <div class="container mt-5" data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
        <h2 class="text-center text-blue fw-bold mb-4 pb-2 font-36">Showcasing Our Premier Projects</h2>
        <div v-if="loading" class="w-100 py-4 d-flex justify-content-center">
            <div class="spinner-grow text-blue" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else class="projectContainer d-md-flex d-block">
            <template v-for="(project, index) in projects" :key="project.id">
                <input class="projectInput" type="radio" name="slide" :id="'c' + project.id" :checked="index === 0"
                    @change="getCheckedRadio" />
                <label :for="'c' + project.id" class="projectCard">
                    <div class="projectRow">
                        <div class="ms-1">
                            <div class="d-flex flex-lg-column align-items-center my-2 my-xl-0">
                                <div class="projectIcon font-20 fw-bold">{{ formatIndex(index + 1) }}</div>
                                <div class="font-16 fw-500 vertical-text text-uppercase-m">{{ project.category.name }}
                                </div>
                            </div>
                        </div>
                        <div class="projectDescription align-items-center align-items-xl-start ms-0 ms-lg-4">
                            <p class="font-30 fw-bold mt-4 mt-lg-0 text-center text-lg-start pe-lg-3 pe-0">
                                {{ project.project_name }}
                            </p>
                            <p class="mt-0 mt-lg-1 font-16 text-center text-xl-start pe-lg-3 pe-0">{{ project.description }}</p>
                            <router-link :to="`/solutions/${project.category.slug}`" @click="scrollToTop"
                                class="btn border border-white rounded-pill text-white font-16 fw-bold mt-4 mt-lg-4 w-50 hvr-shrink">
                                Discover More
                            </router-link>
                        </div>
                        <div class="projectImages d-block d-md-flex mt-2 mt-xl-0 ms-auto">
                            <img :src="project.image" class="text-center mx-auto rounded-25 cus-project-img"
                                :alt="project.project_name" />
                        </div>
                    </div>
                </label>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectSection',
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        projects() {
            return this.$store.state.categoryData.projects;
        },
    },
    created() {
        this.getProjects();
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        formatIndex(index) {
            return index.toString().padStart(2, '0');
        },
        getProjects() {
            this.loading = true;
            this.$store.dispatch('categoryData/getProjects')
                .then(() => {
                    this.loading = false;
                    // Call getRadios after projects have been loaded
                    this.$nextTick(() => {
                        this.getRadios();
                    });
                });
        },
        getRadios() {
            // Get all elements with the class name 'projectInput'
            const radioInputs = document.querySelectorAll('.projectInput');

            const ids = [];

            // Iterate over the NodeList
            radioInputs.forEach((radioInput) => {
                const id = radioInput.id;
                ids.push(id);
            });

            // get one by one id and click after 8 seconds
            let index = 0;
            function clickRadio() {
                setTimeout(() => {
                    const element = document.getElementById(ids[index]);
                    if (element) {
                        element.click();
                    }
                    index = (index + 1) % ids.length;
                    clickRadio();
                }, 8000);
            }
            clickRadio();
        },
        resetComponent() {
            this.loading = false;
            this.getRadios();
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('categoryData/resetProjects');
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.resetComponent();
        });
    }
}
</script>

<style>
.cus-project-img {
    object-fit: cover;
    object-position: top;
    width: 100%;
}

.mt-10 {
    margin-top: 100px;
    margin-left: 20px;
}

.projectContainer {
    height: 400px;
}

.projectCard {
    width: 100px;
    border-radius: 30px;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    margin: 0 10px;
    display: flex;
    transition: .6s cubic-bezier(.28, -0.03, 0, .99);
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 5px;
}

.projectCard>.projectRow {
    color: white;
    display: flex;
    flex-wrap: nowrap;
}

.projectIcon {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    margin: 0 20px;
}

.projectCard>.projectRow>.projectDescription {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: auto;
    width: 50%;
    opacity: 0;
    transform: translateY(30px);
    transition-delay: .3s;
    transition: all .3s ease;
}

.projectDescription p {
    color: #b0b0ba;
    padding-top: 5px;
}

.projectDescription h4 {
    text-transform: uppercase;
}

.projectInput {
    display: none;
}

.projectInput:checked+label {
    width: 100%;
    background-color: #6a0dad;
    color: white;
}

.projectInput:checked+label .projectRow {
    color: white;
}

.projectInput:checked+label .projectIcon {
    color: white;
}

.projectInput:checked+label .vertical-text {
    color: white;
}

.projectInput:checked+label .projectDescription {
    opacity: 1 !important;
    transform: translateY(0) !important;
    color: white;
}

.projectInput:checked+label .projectDescription p {
    color: #f0e6ff;
}

.projectInput:not(:checked)+label .projectIcon {
    color: #343a40;
}

.projectInput:not(:checked)+label .vertical-text {
    color: #343a40;
}

@media only screen and (max-width: 767px) {

    .cus-project-img {
        width: 100%;
    }

    .projectContainer {
        height: auto;
        flex-wrap: wrap;
    }

    .projectCard {
        width: 100%;
        margin: 10px 0;
        display: block;
    }

    .projectCard>.projectRow {
        flex-wrap: wrap;
    }

    .vertical-text {
        writing-mode: horizontal-tb;
        text-orientation: initial;
        transform: none;
        text-align: center;
    }

    .projectCard>.projectRow>.projectDescription {
        width: auto !important;
    }

    .projectInput:not(:checked)+label .projectDescription {
        display: none !important;
    }

    .projectInput:not(:checked)+label .projectImages {
        display: none !important;
    }

    .projectIcon {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px;
    }

    .projectCard>.projectRow {
        display: block;
    }

    .text-uppercase-m {
        text-transform: uppercase;
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .projectContainer {
        height: auto;
        flex-wrap: wrap;
    }

    .projectCard {
        width: 100%;
        margin: 10px 0;
        display: block;
    }

    .projectCard>.projectRow {
        flex-wrap: wrap;
    }

    .vertical-text {
        writing-mode: horizontal-tb;
        text-orientation: initial;
        transform: none;
        text-align: center;
    }

    .projectCard>.projectRow>.projectDescription {
        width: auto !important;
    }

    .projectInput:not(:checked)+label .projectDescription {
        display: none !important;
    }

    .projectInput:not(:checked)+label .projectImages {
        display: none !important;
    }

    .projectIcon {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px;
    }

    .projectCard>.projectRow {
        display: block;
    }
}
</style>
