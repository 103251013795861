import axiosClient from "@/axios";
import { createStore } from "vuex";
import categoryData from "./category";
import careersData from "./careers";
import inquiryForm from './Inquiry';
import blogPosts from './blogs';

export default createStore({
  state: {
    token: '',
    error: null,
    recaptchaToken: '',
  },

  mutations: {
    setAuthToken(state, token) {
      state.token = token;
      state.error = null;
    },
    setLoginError(state, error) {
      state.error = error;
    },
    setError(state, error) {
      state.error = error;
      console.error(error);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setRecaptchaToken(state, payload) {
      state.recaptchaToken = payload.configuration_value;
    }
  },
  actions: {

    async getToken({ commit }) {
      let formData = new FormData();
      formData.append("email", "admin@guisrilanka.com");
      formData.append("password", "gui12345");

      let url = "/login";

      await axiosClient.post(url, formData).then((response) => {
        if (response.data.code == 200) {

          const token = response.data.payload.token;
          commit("setAuthToken", token);

          // dispatch("categoryData/getCategories");

        } else {
          commit("setLoginError", "Invalid credentials");

        }
      }).catch(error => {
        commit("setLoginError", error);
      });

    },

    async subscription({ commit }, { email }) {

      commit('setLoading', true);

      let url = "/subscription";

      try {

        let response = await axiosClient.post(url, { email });
        commit('setLoading', false);
        if (response.data.code == 200) {

          return 1;

        } else {

          return 0;
        }

      } catch (error) {
        commit("setLoginError", error);
        commit('setLoading', false);
        return 0;
      }
    },

    // get recapcha token
    async getRecaptchaToken({ commit }) {

      let url = "/recapcha";

      try {
        let response = await axiosClient.get(url);

        if (response.data.code == 200) {

          commit("setRecaptchaToken", response.data.payload);

        } else {

          commit("setRecaptchaToken", "");
        }
        
      } catch (error) {
        commit("setRecaptchaToken", "");
      }
    },


  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getToken: (state) => state.token,
  },
  modules: {
    categoryData,
    careersData,
    inquiryForm,
    blogPosts,
  },
});
