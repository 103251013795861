<!-- LoaderIcon.vue -->
<template>
  <div class="loader-mask" v-if="loading">
    <div class="loader">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  name: 'LoaderIcon',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.hide();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.loading) {
        this.hide();
      }
    }, 2000);
  },
  methods: {
    hide() {
      $('.loader').fadeOut();
      $('.loader-mask').delay(350).fadeOut('slow');
    },
  },
}
</script>

<style scoped>
.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.loader {
  width: 100px;
  height: 100px;
  font-size: 0;
  color: #00c9d0;
  display: inline-block;
  text-indent: -9999em;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader div {
  background-color: #4F0188;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  opacity: .5;
  border-radius: 50%;
  -webkit-animation: ballPulseDouble 2s ease-in-out infinite;
  animation: ballPulseDouble 2s ease-in-out infinite;
}

.loader div:last-child {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes ballPulseDouble {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ballPulseDouble {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>

