<template>
    <div data-aos="zoom-in" data-aos-duration="2000">
        <div class="container-fluid">
            <!-- Carousel Start -->
            <div class="container-fluid">
                <div id="carouselExampleAutoplaying" class="carousel carousel-dark slide custom-height"
                    data-bs-ride="carousel" data-bs-interval="2000">
                    <div class="carousel-inner d-lg-flex d-block align-items-center">
                        <template v-for="category in categories" v-bind:key="category.id">
                            <div class="carousel-item" :class="{ 'active': category.id === activeCategoryId }"
                                :id="category.id">
                                <div class="carousel-caption container w-100">
                                    <router-link :to="category.slug"
                                        class="row align-items-center col-12 custome-position">
                                        <div class="col-lg-8 col-12 py-3 mt-lg-3 mt-0 mobile-position-cus">
                                            <h5
                                                class="text-blue text-lg-start text-center ms-lg-5 font-48 fw-bold mb-1 mt-lg-0 mt-3">
                                                {{ category.name }}
                                            </h5>
                                            <p
                                                class="font-18 lh-base text-dark pe-lg-5 pe-0 text-lg-start text-center ms-lg-5">
                                                {{ category.short_description }}
                                            </p>
                                            <div
                                                class="d-flex d-lg-block justify-content-center align-items-center flex-column">
                                                <img :src="category.images" class="slider-img-cus d-lg-none d-md-block d-none"
                                                    alt="GUI Solutions Lanka (Pvt) Ltd">
                                                <router-link :to="category.slug"
                                                    class="btn hvr-shrink btn-cus-gradient rounded-pill text-start ms-lg-5 mt-2 text-white font-14 fw-bold float-lg-start rounded-padding">
                                                    Discover More
                                                </router-link>
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-4 col-12 py-3 mt-3 d-lg-block d-none d-flex justify-content-center align-items-center">
                                            <img :src="category.images" class="slider-img-cus d-lg-block d-none"
                                                alt="GUI Solutions Lanka (Pvt) Ltd">
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- Carousel End -->
        </div>

        <!-- Service section -->
        <div class="container-fluid py-2 custome-position-services">
            <div class="container">
                <div class="row px-0 px-lg-5">
                    <div class="col-lg-3 col-md-3 col-3 p-lg-3 p-md-1 my-lg-0 my-2" v-for="category in categories"
                        v-bind:key="category.id">
                        <a role="button" :id="category.id + 'icon'"
                            class="d-flex align-items-center flex-column gap-2 iconTransition"
                            @click="setActiveCategory(category.id)">
                            <img :src="category.images" class="border p-3 border-purple services-img iconTransition"
                                :class="{ 'border-20': category.id === activeCategoryId }" alt="image">
                            <h5 class="font-18 text-center text-dark fw-bold w-lg-75 w-100">{{ category.name }}</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel } from 'bootstrap';

export default {
    name: 'AppSlider',
    data() {
        return {
            activeCategoryId: 1
        }
    },
    computed: {
        categories() {
            return this.$store.state.categoryData.categoryData;
        },
    },
    mounted() {
        // Initialize Bootstrap Carousel
        var myCarousel = new Carousel(document.getElementById('carouselExampleAutoplaying'), {
            interval: 5000,
            ride: 'carousel'
        });

        myCarousel._element.addEventListener('slide.bs.carousel', () => {
            let items = document.getElementsByClassName('carousel-item');
            for (let i = 0; i < items.length; i++) {
                if (items[i].classList.contains('active')) {
                    let j = i + 1;
                    if (j >= items.length) {
                        j = 0;
                    }
                    let idVal = items[j].id;
                    document.getElementById(idVal + 'icon').click();
                }
            }
        });
    },
    methods: {
        setActiveCategory(categoryId) {
            this.activeCategoryId = categoryId;
        },
    }
}
</script>

<style>
.iconTransition {
    transition: all 0.5s ease-in-out;
}

.req-img-position {
    position: relative;
    top: 40px;
}

.custom-height {
    height: 440px;
}

.custom-height .carousel-inner {
    height: 100%;
}

.rounded-padding {
    padding: 10px 20px;
}

.carousel-control-next .cus,
.carousel-control-prev .cus {
    padding: 0 .5rem !important;
}

.border-space {
    padding: 5px 3px !important;
}

.about-two-img {
    width: 100%;
    height: 465px;
    object-fit: cover;
}

.about-one-img {
    width: 100%;
    height: 390px;
    object-fit: cover;
}

.btn-cus-width {
    width: 25%;
}

@media only screen and (max-width:767px) and (min-width: 576px) {
    .custom-height {
        height: 450px;
    }

    .custome-position-services {
        top: 50px !important;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 576px) {
    .custom-height {
        height: 360px;
    }
}

@media only screen and (max-width:767px) {
    .btn-cus-width {
        width: 100%;
    }

    .mobile-position-cus {
        position: relative;
        left: 10px !important;
    }

    .slider-img-cus {
        width: 40%;
    }

    .custome-position {
        top: -75px;
    }

    .carousel-item {
        top: -25px;
    }

    .fixed-top {
        background-color: #fff;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 991px) {
    .carousel-item {
        top: 0px;
    }

    .slider-img-cus {
        width: 100%;
    }
}

@media only screen and (max-width: 993px) and (min-width: 768px) {
    .carousel-item {
        top: 160px;
    }

    .custom-height {
        height: 540px;
    }

    .slider-img-cus {
        width: 40%;
    }
}

@media(min-width:1025px) and (max-width:1366px) {
    .custom-height {
        height: 295px !important;
    }

    .slider-img-cus {
        width: 90%;
        margin-left: auto;
    }
}
</style>